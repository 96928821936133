<style scope>
  .role-manage h3{
    padding: 10px 15px;
    color: #000;
  }
  .role-manage h3.nostr{
    color: #ddd;
  }
  .role-list-con{
    padding: 15px;
    overflow: hidden;
  }
  .role-list-con li{
    float:left;
    width: 22%;
    margin-right: 4%;
    margin-bottom: 15px;
    text-align: center;
    color: #444444;
  }
  .role-list-con li:nth-of-type(4n){
    margin-right: 0;
  }
  .role-list-con li div{
    position: relative;
  }
  .role-list-con li span{
    display: block;
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .role-list-con li i{
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: 1px;
    right: 1px;
    background: url("../../assets/image/icon_add03.png") no-repeat;
    background-size: 100% 100%;
  }
  .role-list-con li i.role-icon-calc{
    background: url("../../assets/image/icon_calc.png") no-repeat;
    background-size: 100% 100%;
  }
  .role-list-con li i.role-icon-edit{
    width: 26px;
    height: 20px;
    top: 0px;
    right: 0px;
    background: url("../../assets/image/icon_edit.png") no-repeat;
    background-size: 100% 100%;
  }
  .role-list-con li img{
    width: 100%;
    /*height: 100px;*/
    display: block;
    margin-bottom: 5px;
  }
  .pagebgimg{
      position: fixed;
      top: 0;
      z-index: -1;
      width: 100%;height: 100%;
    }
    .pagebgimg>img{
      width: 100%;height: 100%;
    }
    .inputstyll{
     background: rgba(255,255,255,0.5);
     border: initial;
    }
</style>
<template>
  <div class="role-manage">
    <header-title titleCon="角色管理" titleBtnStr="完成" @titleLeftBtn="titleFnReturn" @titleFnBtn="saveRoleChapter"></header-title>
    <div class="chapter-role">
      <h3 style="border-bottom: 1px solid #eee;" :class="hasTitle ? '' : 'nostr'">{{roleChapterInfo.chapterVo.chapterTitle}}</h3>
      <ul class="role-list-con">
        <li v-for="(item, index) in roleChapterInfo.roleChapterList" :key="index">
          <div>
            <img :src="item.ugcCharacterpicPath + '?x-oss-process=image/crop,x_75,y_10,w_600,h_766'" />
            <i class="role-icon-calc" @click="calcRole(index, item.id)"></i>
          </div>
          <span>{{item.characterName}}</span>
        </li>
      </ul>
    </div>
    <div class="book-role">
      <h3 style="padding-bottom: 0">全部角色</h3>
      <ul class="role-list-con">
        <li v-for="(item, index) in roleBooklist" :key="index">
          <div>
            <img :src="item.ugcCharacterpicPath + '?x-oss-process=image/crop,x_75,y_10,w_600,h_766'" />
            <i class="role-icon-edit" @click="editRole(item.id)"></i>
            <i class="role-icon-add" v-if="item.isNoRepeat" @click="addRole(index)"></i>
          </div>
          <span>{{item.characterName}}</span>
        </li>
        <li>
          <a @click="goAddRole"><img src="../../assets/image/addrole.png" /></a>
        </li>
      </ul>
    </div>
    <div class="pagebgimg">
          <img :src="pagebg" alt="">
    </div>
  </div>
</template>
<script>
import headerTitle from '../../components/header/header-title-transparent.vue'
import qs from 'qs'
// import qs from 'qs'
export default {
  components: {
    headerTitle
  },
  data () {
    return {
      windowHeight: document.documentElement.clientHeight,
      pagebg: require('../../assets/image/pagebgsm.png'),
      hasTitle: true,
      roleChapterInfo: {
        isExist: false,
        roleChapterListArr: [],
        chapterVo: {
          chapterTitle: '',
          chapter_Number: 0,
          id: ''
        },
        roleChapterList: [
          // {
          //   addTime: '2019-12-12T08:47:19.000+0000',
          //   characterIntro: null,
          //   characterName: '灰太狼',
          //   characterNickname: '',
          //   id: 20000000220762,
          //   isNoRepeat: true,
          //   joinStatus: 0,
          //   joinTimes: 0,
          //   ugcBookId: 20000000217283,
          //   ugcCharacterpicId: 20000000220864,
          //   ugcCharacterpicPath: 'http://img3.zuok.cn/down/upload/ugcCharacter/1576120832376.jpg',
          //   updTime: null
          // }
        ]
      },
      roleBooklist: []
    }
  },
  computed: {
  },
  created () {
    // 适配背景图片
    if (this.windowHeight > 736) {
      this.pagebg = require('../../assets/image/pagebgbig.png')
    }
    var that = this
    this.axios({
      method: 'get',
      url: '/v1/characterManage',
      params: {
        chapterId: that.$route.query.chapterId,
        ugcBookId: that.$route.query.bookId
      }
    }).then((res) => {
      if (res.data.head.flag === 0) {
        that.roleBooklist = res.data.content.allCharacters || []
        that.roleChapterInfo.chapterVo = res.data.content.chapterVo || {}
        that.roleChapterInfo.roleChapterList = res.data.content.chapterCharacters || []
        if (this.roleChapterInfo.chapterVo.chapterTitle === '' || this.roleChapterInfo.chapterVo.chapterTitle === undefined) {
          this.hasTitle = false
          this.roleChapterInfo.chapterVo.chapterTitle = '未写章节名'
        }
        if (this.$store.state.chapterInfo.chapterName !== '') {
          this.hasTitle = true
          this.roleChapterInfo.chapterVo.chapterTitle = this.$store.state.chapterInfo.chapterName
        }
        // 判断全部角色中已存在章节角色不显示+
        that.roleBooklist.forEach(function (e) {
          e.isNoRepeat = true
          that.roleChapterInfo.roleChapterList.forEach(function (event) {
            if (e.id === event.id) {
              e.isNoRepeat = false
            }
          })
        })
      } else {
        this.$toast(res.data.head.desc)
      }
    })
  },
  mounted () {
    this.roleChapterListArr = this.$store.state.chapterInfo.roleList
  },
  methods: {
    // 返回
    titleFnReturn () {
      this.$router.push({ path: '/chapterAdd', query: { bookId: this.$route.query.bookId, chapterId: this.$route.query.chapterId, chapterType: this.$route.query.chapterType, type: this.$route.query.type, roleId: this.$route.query.roleId } })
    },
    // 选择背景完成
    // titleFnListener () {},
    // 章节添加角色
    addRole (index) {
      this.roleBooklist[index].isNoRepeat = false
      this.roleChapterInfo.roleChapterList.push(this.roleBooklist[index])
    },
    editRole (id) {
      this.$router.push({ path: '/roleAdd', query: { bookId: this.$route.query.bookId, chapterId: this.$route.query.chapterId, chapterType: this.$route.query.chapterType, type: this.$route.query.type, roleId: id, roleType: 'edit' } })
    },
    // 删除角色
    calcRole (index, id) {
      var that = this
      that.roleChapterListArr.forEach(function (event) {
        if (id === event.ugcCharacter.id) { // 已加入剧情的角色
          that.isExist = true
        }
      })
      if (that.isExist) {
        that.$toast('该角色已加入剧情，不能取消')
        that.isExist = false
      } else {
        that.roleChapterInfo.roleChapterList.splice(index, 1) // 删除章节角色
        that.roleBooklist.forEach(function (e) {
          if (id === e.id) { // 全部角色中删除的角色修改状态
            e.isNoRepeat = true
          }
        })
      }
    },
    // 去创建角色
    goAddRole () {
      this.$store.state.roleInfo = {
        roleName: '',
        roleNickname: '',
        roleIntro: '',
        roleImg: ''
      }
      this.$router.push({ path: '/roleAdd', query: { bookId: this.$route.query.bookId, chapterId: this.$route.query.chapterId, chapterType: this.$route.query.chapterType, chapterSet: this.$route.query.chapterSet, type: this.$route.query.type, roleType: 'add' } })
    },
    // 完成
    saveRoleChapter () {
      const roleIds = []
      this.roleChapterInfo.roleChapterList.forEach(function (e) {
        roleIds.push(e.id)
      })
      this.axios({
        method: 'post',
        url: '/v1/saveCharacters',
        data: qs.stringify({
          chapterId: this.$route.query.chapterId,
          characterIds: JSON.stringify(roleIds),
          ugcBookId: this.$route.query.bookId
        })
      }).then((res) => {
        if (res.data.head.flag === 0) {
          // this.$router.go(-1)
          if (parseInt(this.$route.query.chapterSet) === 0) {
            this.$router.push({ path: '/chapterAdd', query: { bookId: this.$route.query.bookId, chapterId: this.$route.query.chapterId, chapterType: this.$route.query.chapterType, type: this.$route.query.type, roleId: this.$route.query.roleId } })
          } else {
            this.$router.push({ path: '/chapterCon', query: { bookId: this.$route.query.bookId, chapterId: this.$route.query.chapterId, chapterType: this.$route.query.chapterType, type: this.$route.query.type, roleId: this.$route.query.roleId } })
          }
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    }
  }
}
</script>
